import React from "react"
import { FaBroadcastTower, FaExchangeAlt, FaUtensils } from "react-icons/fa"

export default [
  {
    icon: <FaBroadcastTower />,
    title: "在地情報",
    text: "掌握信義區巷弄內的美食 ",
  },
  {
    icon: <FaExchangeAlt />,
    title: "分類查詢",
    text: "看想吃什麼，便利的分類查詢",
  },
  {
    icon: <FaUtensils />,
    title: "無廣告干擾",
    text: "得到更好的網站體驗",
  },
]
